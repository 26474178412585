import React from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";


const allBlogs = [
  {
    id: 28,
    title: "A Year In Books - 2023",
    image: "/images/blog/a-year-in-books-2022.png",
    filesource: "../../blogs/a-year-in-books-2023.md",
    date: "February 23, 2024",
    category: "Books",
  },{
    id: 27,
    title: "Implementing Facebook Conversions API Using Google Tag Manager Server-Side",
    image: "/images/blog/fb_capi6.jpg",
    filesource: "../../blogs/facebook-capi-gtm.md",
    date: "December 17, 2023",
    category: "Tech, Analytics",
  },{
    id: 26,
    title: "Implementing TikTok Events API",
    image: "/images/blog/tiktok-events-api.png",
    filesource: "../../blogs/implementing-tiktok-events-api.md",
    date: "September 12, 2023",
    category: "Tech, Analytics",
  },
];


function Blogs() {
  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="Latest Posts" />
        <div className="row blog-wrapper">
          {allBlogs.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blogs" className="btn btn-default">
            Show all blogs
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Blogs;